<template>
  <div class="outer">
    <div class="sub-banner">
      <div class="bgImg">
        <div class="sub-txt">
          <h6>online shopping</h6>
          <p>在线商城</p>
        </div>
      </div>
    </div>
    <div class="center-banner"></div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.outer {
  width: 100%;
  .sub-banner {
    height: 300px;
    position: relative;

    .bgImg {
      width: 100%;
      height: 100%;
      background: url(../../../public/imgs/backgroundImg/01.jpg) no-repeat
        center center;

      .sub-txt {
        text-align: center;
        width: 1200px;
        margin: 0 auto;
        padding-top: 90px;

        h6 {
          font-family: Arial;
          font-size: 40px;
          font-weight: bold;
          color: #003f3c;
          text-transform: uppercase;
        }

        p {
          font-size: 30px;
          color: #003f3c;
          line-height: 40px;
        }
      }
    }
  }
  .center-banner {
    margin: 0 auto;
    background: url("../../../public/imgs/backgroundImg/u=3137863005,4227718428&fm=26&gp=0.jpg")
      no-repeat center center;
    width: 600px;
    height: 400px;
  }
}
</style>